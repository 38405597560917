'use client'
import Script from 'next/script'
import { getCountryKey } from 'helpers/country'

const BingClarity = () => {
  const countryKey = getCountryKey()
  const isProd = process.env.ENV === 'production'
  const clarityList = [{ ph: 'penaz8v7n0' }, { jp: 'penab1xft7' }, { sg: 'peopzq6oy3' }]
  const matchValue = clarityList.find((item) => item[countryKey])?.[countryKey]
  if (matchValue && isProd) {
    return (
      <Script
        id='clarity-init'
        dangerouslySetInnerHTML={{
          __html: `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${matchValue}");

      `
        }}
      />
    )
  }
  return null
}

export default BingClarity
