"use client";

import { useEffect, useState } from "react";

const throttle = (func, limit) => {
  let inThrottle;
  return (...args) => {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const ClientScrollHandler = () => {
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    const headerElement = document.getElementById("header"); // 获取 header 元素

    // 检查 header 是否为 sticky
    const isSticky = headerElement
      ? window.getComputedStyle(headerElement).position === "sticky"
      : false;

    const handleScroll = () => {
      // 当 body 滚动时，检查滚动位置
      if (isSticky && window.scrollY > 64) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    const throttledScroll = throttle(handleScroll, 10);

    window.addEventListener("scroll", throttledScroll);

    return () => {
      window.removeEventListener("scroll", throttledScroll);
    };
  }, []);

  useEffect(() => {
    const headerElement = document.getElementById("header");
    if (headerElement) {
      headerElement.style.boxShadow = hasShadow
        ? "0px 6px 6px 0px rgba(162, 183, 203, 0.10)"
        : "none";
    }
  }, [hasShadow]);

  return null;
};

export default ClientScrollHandler;